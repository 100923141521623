import { CarFirebaseObject } from '@core/models/car-firebase.model';
import { ItemCarantyHostCRM, ListEstado, ShowRoomStateAll } from '@core/models/responsehttp.model';
import { UserSession } from '@core/models/user-roles.model';
import { ProfileType } from '@core/models/utils.model';
import { createAction, props } from '@ngrx/store';
import { NotificationObject } from 'src/app/contratos/models/contrato.model';
import { TallerInspeccionObject } from 'src/app/pagos-inspeccion/models/pagos-inspeccion.model';

//CATALOGO DE ESTADOS
export const initCatalogueState = createAction('[CatalogueState API] Load init');
export const loadSuccessCatalogueState = createAction(
  '[CatalogueState API] Loaded success',
  props<{ state: ListEstado[] }>()
);
export const loadErrorCatalogueState = createAction(
  '[CatalogueState API] Loaded error',
  props<{ errorMessage: string }>()
);

//CATALOGO DE SHOWROOMS POR ESTADO
export const initCatalogueShowroomAll = createAction('[CatalogueShowroomAll API] Load init');
export const loadSuccessCatalogueShowroomAll = createAction(
  '[CatalogueShowroomAll API] Loaded success',
  props<{ showroomsAll: ShowRoomStateAll[] }>()
);
export const loadErrorCatalogueShowroomAll = createAction(
  '[CatalogueState API] Loaded error',
  props<{ errorMessage: string }>()
);

//CATALOGO DE HOST CRM -- LOS QUE HACEN LOGIN EN CRM
export const initCatalogueCHostCRM = createAction('[ListCarantyHostCRM API] Load init');
export const loadSuccessCatalogueCHostCRM = createAction(
  '[ListCarantyHostCRM API] Loaded success',
  props<{ carantyhostCRM: ItemCarantyHostCRM[] }>()
);
export const loadErrorCatalogueCHostCRM = createAction(
  '[ListCarantyHostCRM API] Loaded error',
  props<{ errorMessage: string }>()
);

//CATALOGO DE BANCOS
export const initCatalogueBanks = createAction('[ListCarantyBanks API] Load init');
export const loadSuccessCatalogueBanks = createAction(
  '[ListCarantyBanks API] Loaded success',
  props<{ banks: string[] }>()
);
export const loadErrorCatalogueBanks = createAction(
  '[ListCarantyBanks API] Loaded error',
  props<{ errorMessage: string }>()
);

//ACCION PARA LLAMAR LOS CATALOGOS QUE SE OCUPAN EN CONTRATOS
export const initCatalogueContratos = createAction('[CatalogueContratos API] Load init');

//Accion para llamar catalogos que se ocupan en Calendario
export const initCatalogueCalendario = createAction('[CatalogueCalendario API] Load init');

//ACCIONES PARA DATOS DEL USUARIO EN SESSION
export const initUserSession = createAction('[GRAPH API] Load init');
export const loadSuccessUserSession = createAction(
  '[GRAPH API] Loaded success',
  props<{
    userProfile: UserSession;
    usersNotificaMC: string[];
    usersAutorizaComisionContrato: string[];
    usersNotificaMCSolicitudContrato: NotificationObject;
    usersNotificaSolicitudPago: NotificationObject;
  }>()
);
export const loadErrorUserSession = createAction('[GRAPH API] Loaded error', props<{ errorMessage: string }>());

export const initListChostAllCompany = createAction('[GRAPH-LIST CHOST API] Load init', props<{ email: string }>());
export const loadSuccessListChostAllCompany = createAction(
  '[GRAPH-LIST CHOST API] Loaded success',
  props<{ listChost: ProfileType[]; listCHostAquienPuedeVer: ProfileType[] }>()
);
export const loadErrorListChostAllCompany = createAction(
  '[GRAPH-LIST CHOST API] Loaded error',
  props<{ errorMessage: string }>()
);

export const initLoadResorces = createAction('[RESOURCES CRM API] Load init');
export const loadSuccessloadResorcesCRM = createAction(
  '[RESOURCES CRM API] Loaded success',
  props<{ resourcesCRM: any }>()
);

export const initGetCarsPublish = createAction('[PUBLISH API] Load init');
export const loadSuccessGetCarsPublish = createAction(
  '[PUBLISH API] Loaded success',
  props<{ cars: CarFirebaseObject[] }>()
);
export const loadErrorGetCarsPublish = createAction('[PUBLISH API] Loaded error', props<{ errorMessage: string }>());

export const initLoadTallerInspeccion = createAction('[TALLER INSPECCION API] Load init');
export const loadSuccessloadTallerInspeccion = createAction(
  '[TALLER INSPECCION API] Loaded success',
  props<{ talleresInspeccion: TallerInspeccionObject[] }>()
);

export const resetStore = createAction('[RESET] STORE');
